@import '../../variables.scss';
@import "../../assets/functions.scss";


.detailsModal{
  width: auto;
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-direction: column;

  .description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: rgba(59, 76, 83, 0.7);
    overflow: scroll;
    max-height: 195px;
  }

  .title{
    height: 29px;
    left: 500px;
    top: 166px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #3B4C53;
  }

  .price{
    color: #3B4C53;
    &.intoImg{
      display: none;
    }
  }

  .image{
    height: 220px;
    width: 440px;
    float: left;
    margin-bottom: 26px;
    
    .img{
      width: 100%;
      height: 100%;
    }
  }

  .addToCartButton{
    margin: 15px 0px 25px 0px;
    span{
      margin: 0 auto;
      line-height: 35px;
    }
  }

  .wrapper-details{
    display: inline-block;
    width: 440px;

  .quantity-wrapper{
    width: 124px;
    height: 38px;
    border: 1px solid rgba(59, 76, 83, 0.2);
    border-radius: 24px;
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 7px;
    margin: 15px 0px 25px 0px;
    
    &:hover{
      color: #93979D;
    }

    .remove{
      float: left;
      line-height: 22px;
    }

    .add{
      float: right;
      line-height: 22px;
    }

    .quantity{
      margin: 0 auto;
      line-height: 25px;
    }
  }

  }

@media only screen and (max-width: 600px) {
    width: auto;
    height: auto;
    margin: auto;
    padding: 0;
    display: block;
    

    .wrapper-details{
      min-width: 230px;
      width: 312px;

      .description{
        max-height: 374px;
        padding-right: 5px;

        &.noImg{
          max-height: 548px;
        }
      }
    }

    .priceModal{
      display: none;
    }

  .image{
    display: block;
    float: none;
    margin: auto;
    width: 315px;
    height: 175px;
    margin-bottom: 15px;

    .price{
      &.intoImg{
        @include price();
        margin: 5px 20px;
        display: block;
      }
    }
  }

  .quantity-wrapper{
    display: inline-flex;
    margin-bottom: 21px;
  }

  .addToCartButton{
    margin-bottom: 21px;
    width: 124px;
    height: 38px;
  }

 }

 @media only screen and (max-width: 400px) {

  .wrapper-details{
    min-width: auto;
    width: 288px;
  }

  .image{
    width: 288px;
  }

 }

}


h2{
  text-align: right;
}

.closeIcon{
  cursor: pointer;
}

#alert-dialog-title{
  padding: 10px 13px 0 24px;
}

@media only screen and (max-width: 600px) {
  #dialog > div{
  align-items: flex-start;
  padding-top: 17px;
  }
}


