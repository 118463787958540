.header{
  background: #FFFFFF;
  width: 100%;
  line-height: 66px;
  font-family: Poppins;


  .header-inner{
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    padding-right: 7px;
    max-width: 1179px;

    &.no-logo{

      .left-side{
        margin-top: 0px;
        .content{
          margin: 0;
          .account-name,.account-slogan{
            margin-left: 0px;
          }
        }
      }
    }

    .left-side{
      display: flex;
      margin-top: 13px;

      .account-logo{
        width: 125px;
        height: 74px;
        margin-right: 16px;
        border-radius: 4px;
        margin-bottom: 12px;
        object-fit: contain;
      }

      .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 13px;

        .account-name{
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          color: #3E4B51;
        }

        .account-slogan{
          color: rgba(62, 75, 81, 0.6);
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .right-side{
      font-size: 12px;
      line-height: 18px;
      display: flex;

      .powered-by{
        color: rgba(62, 75, 81, 0.8);
        line-height: 29px;
      }

      img{
        display: block;
        margin: 25px 0px 6px 0px;
        width: 95px;
        height: 47px;
      }

      .shopping-cart-wrapper{
        display: none;
      }
    }
  }




  @media only screen and (max-width: 600px) {
    width: 100%;
    line-height: normal;



    .header-inner{
      width: 92%;
      justify-content: center;
      padding: 16px 0px 12px;
      &.no-logo{
        padding-left: 0px;

        .left-side{
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .content{
            margin-left: 9px;
            text-align: center;

            .account-name,.account-slogan{
              margin-left: 0px;
            }
          }
        }
      }

      .left-side{
        margin: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .account-logo{
          width: 72px;
          height: 42px;
          margin: 0 0 12px 0;
        }

        .content{
          margin: 0;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .account-name{
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            margin: 0;
            text-align: center;
            letter-spacing: 0.4px;
        }
          .account-slogan{
            font-size: 14px;
            margin: 0;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }

      .right-side{
        .powered-by{
          display: none;
        }

        .workiz-logo{
          display: none;
        }

        .shopping-cart-wrapper{
          .amount {
            background: #50D58C;
            border-radius: 33px;
            height: 18px;
            width: 40px;
            color: #FFFFFF;
            position: absolute;
            font-size: 13px;
            line-height: 17px;
            text-align: center;
            top: 13px;
            right: 38px;
            font-weight: 500;
          }
        }
      }
    }
  }
}