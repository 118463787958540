@import '../../variables.scss';


.order-details{
  border-radius: 0px;
  border-top: 4px solid #6AA8EE;

  .top{
    display: flex;
    height: 64px;
    line-height: 64px;
    justify-content: space-between;
    color: #3E4B51;
    position: relative;
    margin-top: 48px;

    .title{
      margin-left: 37px;
      color: #3E4B51;
      font-weight: 600;
      font-size: 18px;
    }

    .shopping-cart{
      position: relative;
      display: flex;

      .price{
        color: #3B4C53;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
        font-weight: 400;
        margin-right: 12px;
        line-height: 67px;
      }

      .cart{
        height: 30px;
        width: 34px;
        margin: 16px 25px 0px 0px;
      }

      .quantity{
        background: #50D58C;
        width: 16px;
        color: #FFFFFF;
        height: 16px;
        border-radius: 50px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        position: absolute;
        top: 13px;
        right: 48px;
      }
    }

  }

  .wrapper{
    margin-top: 20px;
    padding-bottom: 24px;

    .step{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .service-wrapper{
        margin-left: 51px;
      }
      
      .progress-line{
        width: 8px;
        height: 99%;
        border: 1px solid #6F7B80;
        border-radius: 2px;
        border-right: none;
        position: absolute;
        left: 38px;
        top: 22px;

        &.second{
          top: 22px;
          height: 73px;
        }

        &.third{
          top: 22px;
          height: 72.5px;
        }
      }

      .step-text{
        font-size: 12px;
        height: 39px;
        margin-left: 50px;
        line-height: 45px;
        &.address{
          line-height: 25px;

          .full-address-part{
            margin-right: 5px;
          }
        }
      }
    }

    .service-wrapper{
      margin: 16px auto;
      width: 236px;

      .service-details-row{
        width: 236px;
        display: flex;
        font-weight: normal;
        font-size: 12px;
        justify-content: space-between;

        .service-name{
          width: 120px;
        }

        .service-quantity{
          display: flex;
          justify-content: flex-end;
          margin-bottom: 10px;

          .controls{
            background: #E8E8E8;
            width: 16px;
            height: 16px;
            line-height: 16px;
            cursor: pointer;
            text-align: center;
            border-radius: 25px;
          }

          .quantity{
            margin: 0px 13px;
            line-height: 15px;
          }
        }

        .total-amount-sum{
          text-align: right;
          width: 40px;
        }
      }

      .service-total-row{
        display: flex;
        justify-content: space-between;

        .total-amount{
          color: rgba(62, 75, 81, 0.6);
        }
      }
    }
  }

  .tax{
    width: 240px;
    height: 13px;
    padding-top: 10px;
    border-top: solid 1px #3B4C53;
    display: flex;
    font-size: 12px;
    margin: 1px auto 0px;
    justify-content: space-between;
  }

  .total-payment{
    width: 240px;
    height: 38px;
    padding-top: 10px;
    display: flex;
    font-weight: 600;
    font-size: 12px;
    margin: 1px auto 0px;
    justify-content: space-between;

    &.no-tax{
      border-top: solid 1px #3B4C53;
    }
  }


  @media only screen and (max-width: 600px) {
    border-top: none;
    display: none;

    .close-wrapper{
      font-weight: 600;
      font-size: 12px;
      color: #899498;
      margin: 15px 0px 0px 15px
    }
  }
}