
.error-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .big404 {
        margin: 18px 0 16px;
    }

    span {
        font-size: 80px;
        font-weight: 600;
        font-family: Poppins;
        color: #3B4C53;

        &.sub-title {
            font-weight: 400;
            font-size: 13px;
        }
    }

    @media only screen and (max-width: 600px){
        .sub-title {
            max-width: 232px;
            text-align: center;
        }
    }
}
