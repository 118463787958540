.jobTypes{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .container{
    box-shadow: 0px 8px 16px rgba(59, 75, 82, 0.15), 0px 0px 4px rgba(59, 75, 82, 0.05);
    width: 712px;
    height: 101px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: white;

    @media only screen and (max-width: 776px) {
      width: 500px;

    }

    @media only screen and (max-width: 600px) {
      width: 373px;
      height: 76px;
    }

  }
  
  .colorSelected{
    border-left:solid #6AA8EE 3px;
    p{
      color: #6AA8EE;
      font-family: 'Poppins';
    }
  } 