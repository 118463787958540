@import '../../variables.scss';

.client-details {
  font-family: Poppins;
  height: calc(100vh - 121px);
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
  margin: 0px -50px;
  padding: 0px 50px 20px 50px;

  .client-details-input {
    height: 48px;
    width: 348px;
    margin: 0 auto;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    width: 800px;
    min-height: 448px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    font-family: Poppins;


    .fields {
      display: flex;
      padding: 32px;

      .MuiPrivateNotchedOutline-root-63 {
        border-radius: 2px;
        border: 1px solid rgba(158, 166, 170, 0.8);
      }

      input {
        padding: 14px 14px;
        color: #3B4C53;
        font-size: 14px;
        color: #3B4C53;
      }

      #select-state,#select-country {
        font-size: 14px;
        margin-left: 12px;
        margin-top: 4px;
        color: #3B4C53;
      }

      label {
        top: -4px;
        color: #919B9E;

        &.MuiFormLabel-filled-30 {
          top: 0px;
        }

        &.MuiFormLabel-focused-27 {
          top: 0px;
        }

        &.state-floating-label {
          pointer-events: none;
          top: -7px;
          padding-left: 8px;
          left: 12px;
          z-index: 1;
          position: absolute;
          background: #fff;

          &.MuiFormLabel-filled-30 {
            background-color: #ffffff;
            top: -6px;
            left: 10px;
            z-index: 999;
            padding: 0px 5px;
          }
        }

      }

      .left-wrapper {
        width: 360px;
        margin-right: 32px;

        .input-field {
          width: 100%;
          margin-bottom: 16px;

          &.phone {
            margin-bottom: 32px;
          }

          input {
            padding: 14px 14px;
            color: #3B4C53;
            font-size: 14px;
          }
        }

        .form-flex-row {
          display: flex;
          justify-content: space-between;
          height: 70px;

          &.second{
            height: auto;
          }
        }

        .small-input-field {
          width: 172px;
        }

        .select-field {
          height: 46px;
          width: 170px;
          border: 1px solid rgba(158, 166, 170, 0.8);
          border-radius: 2px;
          margin-top: 0px;

          &:before {
            border-bottom: none;
          }

          &:hover {
            &:before {
              border-bottom: solid 1px #E2E2E2;
            }
          }
        }


        .custom-fields{
          margin-top: 16px;
          &>div{
            width: 100%;
            margin-bottom: 16px;
          }

          .custom-select{
            width: 100%;
            margin-bottom: 16px;
          }

            .select-field{
              width: 97.2%;
              padding-left: 2.8%;
              font-size: 14px;
              color: #3B4C53;
            }
        }
      }

      .right-wrapper {
        .attach-image-wrapper {
          text-align: center;
          width: 344px;
          margin-top: 10px;
          height: 126px;
          border-radius: 2px;
          color: #6AA8EE;

          &.no-description{
            margin-top: 0px;
          }

          .file-error {
            color: $error;
            font-size: 11px;
          }

          .no-images-yet {
            display: flex;
            width: 344px;
            height: 124px;
            justify-content: center;
            align-content: center;
            background: rgba(106, 168, 238, 0.1);
            border-radius: 2px;
            border: 1px dashed #6AA8EE;

            .file-input{
              cursor: pointer;
              display: flex;
              margin-top: 53px;

              img{
                width: 30px;
                height: 20px;
              }
            }

            & > input {
              display: none;
            }
          }

          label {
            cursor: pointer;

            &img {
              width: 20px;
              cursor: pointer;
            }
          }

          .has-images {
            display: flex;
            flex-direction: column;
            position: relative;

            .attachments-title{
              position: absolute;
              color: rgba(98, 112, 117, 0.7);
              font-size: 11px;
              background: #FFFFFF;
              top: -8px;
              left: 10px;
              padding: 0px 3px;
            }

            & > input {
              display: none;
            }

            .upload-files-wrapper {
              display: flex;
              cursor: pointer;

              .upload-more-files {
                line-height: 24px;
                color: $main-color;
                font-size: 13px;
                font-weight: 600;
                letter-spacing: 0.018em;
                margin-right: 8px;
              }

              .plus-files {
                border-radius: 8px;
                background: #F7F8F8;
                width: 56px;
                height: 56px;
                color: $main-color;
                font-size: 30px;
                line-height: 57px;

                &:hover {
                  background: rgba(106, 168, 238, 0.2);
                  color: $main-color;
                }
              }
            }

            .preview-files {
              width: 312px;
              padding: 24px 16px 11px 16px;
              margin-top: 0px;
              border: 1px solid rgba(158, 166, 170, 0.8);
              border-radius: 2px;
              display: flex;
              flex-direction: column;

              .clear-all-wrapper{
                display: flex;
                cursor: pointer;
                margin-top: 10px;

                .sign{
                  width: 16px;
                  height: 16px;
                }

                .clear-all-text{
                  line-height: 17px;
                  color: #FF6F64;
                  font-weight: 600;
                  font-size: 11px;
                }
              }

              .files{
                display: flex;
                flex-wrap: wrap;
              }

              .preview-image-wrapper {
                position: relative;
                width: 56px;
                margin-right: 8px;

                .image-delete {
                  position: absolute;
                  right: -8px;
                  top: -9px;
                  cursor: pointer;
                  display: none;
                }

                &:hover{
                  .image-delete{
                    display: block;
                  }
                }
              }
            }
          }

          .text {
            margin: 0px 8px;
            color: #6AA8EE;
            font-weight: 600;
            font-size: 14px;
          }

          .image-preview {
            width: 56px;
            height: 56px;
            border-radius: 7px;
            margin-right: 14px;
          }
        }
      }
    }


    .description,.textarea {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      outline: none;
      width: 312px;
      height: 83px;
      resize: none;
      padding: 13px 16px 11px;
      border-radius: 2px;
      border: 1px solid #B8BFC1;
    }

    .description-error{
      color: $error;
      font-size: 11px;
      margin-left: 8px;
    }

    .textarea{
      width: 324px;
    }

    & > input {
      display: none;
    }

  }

  @media only screen and (max-width: 1258px) {
    width: 100%;

    .form-wrapper {
      width: 100%;
      max-width: 470px;
      margin: auto;

      .textarea{
        width: 92%;
        padding: 10px 4%;
      }

      .custom-fields{
        &:first-child{
          width: 100%;

          &:first-child{
            font-size: 14px;
            margin-left: 12px;
            margin-top: 4px;
            color: #3B4C53;
          }
        }
      }

      .fields{
        flex-direction: column;
        padding: 0px;
        padding-bottom: 20px;

        .left-wrapper{
          width: 92%;
          margin: 25px 4% 0px 4%;

          .custom-select{
            width: 100%;
          }

          .input-field{
            &.phone{
              margin-bottom: 16px;
            }
          }

          .form-flex-row{

            &.city-and-state,&.second{
              &>div{
                width: 48%;
              }
            }
          }


          .select-field{
            width: 100%;
          }

        }

        .right-wrapper{
          width: 92%;
          margin: 0px 4%;

          .description{
            width: 92%;
            padding: 4%;
          }

          .attach-image-wrapper{
            width: 100%;
            height: auto;

            .no-images-yet{
              width: 100%;
              height: 56px;

              .file-input{
                margin-top: 20px;
              }
            }

            .has-images{
              width: 100%;

              .preview-files{
                width: 94%;
                padding: 17px 3% 4px 3%;

                .preview-image-wrapper{
                  .image-delete{
                    display: block;
                  }
                }
              }


            }


          }
        }
      }


    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0px 4% 20px 4%;
    margin: auto;
    height: 100%;
    width: auto;
    overflow-y: inherit;

    .form-wrapper {
      max-width: inherit;
    }
  }

}