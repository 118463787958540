@import 'variables.scss';
@import "./assets/functions.scss";

body{
    background: rgba(62, 75, 81, 0.04);
}

#root{
    height: 100%;
}

.app-loading{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    flex-wrap: wrap;
}

.MuiFormHelperText-root-98,.MuiFormHelperText-contained-102 {
    color: #FF6F64;
}

.booking{
    height: 100%;
    font-family: Poppins;
    &.not-scrollable{
        position: fixed;
    }
    
    &.iframe .order-details-wrapper {
        justify-content : unset !important;
    }

    .spinner-loader,
    .spinner-loader:after {
        border-radius: 50%;
        width: 10em;
        height: 10em;
    }
    .spinner-loader {
        margin: 7px auto;
        font-size: 2px;
        position: relative;
        text-indent: -9999em;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }


    .main{
        display: flex;
        justify-content: center;
        padding-left: 20px;

        .content{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: space-between;
            max-width: 1210px;

            &.no-order-details-wrapper{
                .steps{
                    width: 100%;
                    display: block;
                }
            }

            .steps{
                display: flex;
                width: 100%;

                &.step2, &.step4{
                    overflow-y: scroll;
                    &::-webkit-scrollbar { width: 0 !important }
                    height: calc(100vh - 100px);
                    padding-left: 50px;
                    margin-left: -50px;
                    padding-right: 50px;
                    margin-right: -50px;
                }
            }

            .main-loader{
                left: 50%;
                transform: translate(-50%, 0);
                top: 50%;
                position: absolute;
            }

            .Mui-error{
                color: $error;
            }

            .order-details-wrapper{
                width: 340px;
                min-width: 340px;
                background: #FFFFFF;
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                margin-left: 20px;

                &.without-margin-left {
                    margin-left: 0px;
                }
                .cart-wrapper {
                        display: none;
                }
                
                .next-step{
                    color: #C4C4C4;
                    font-weight: 600;
                    font-size: 12px;
                    margin-left: 69px;
                }

                .next-step-button{
                    @include addToCartButton(215px);

                    &.with-hover:hover{
                        background: rgba(80, 213, 140, 0.1);
                        border: 1px solid #50D58C;
                        color: #50D58C;
                    }

                    &.with-hover:hover{
                        background: rgba(80, 213, 140, 0.1);
                        border: 1px solid #50D58C;
                        color: #50D58C;
                    }

                    &.disabled{
                        // @include disabledButton();
                        pointer-events: none;
                        background-color: #9EA6AA;
                        font-weight: 600;
                        font-size: 14px;
                        color: #FFFFFF;
                        border: 1px solid transparent;
                    }

                    &.semi-disabled, &.semi-disabled:hover {
                        background-color: #9EA6AA;
                        font-weight: 600;
                        font-size: 14px;
                        color: #FFFFFF;
                        border: 1px solid transparent;
                        cursor: not-allowed;
                    }

                    &:hover{
                        // @include hoverOnButton();
                    }
                }
            }
        }
    }


}

@media only screen and (max-width: 1024px) {
    .step2 {
        flex-direction: column;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .booking{

        .mobile-gap{
            width: 100%;
            height: 9.5%;
            top: 0px;
            position: fixed;
            z-index: 107;
            backdrop-filter: blur(2px);
            background: rgba(0, 0, 0, 0.5);
        }

        .spinner-loader {
            margin: 3px auto;
        }

        .main{
            padding:0px;
            max-height: 75%;
            overflow: auto;

            .content{
                height: 100%;
                width: 100%;
                border-radius: 0px;
                padding: 0px;
                margin:0 auto;

                .steps{
                    width: 100%;
                    flex-direction: column;
                    padding-bottom: 70px;

                    &.step2 {
                        padding-left: 0;
                        margin-left: 0;
                        padding-right: 0px;
                        margin-right: 0px;
                        overflow-y: inherit;
                        height: inherit;
                    }
                }

                .order-details-wrapper{
                    border-top: 4px solid #6AA8EE;
                    background: rgba(255, 255, 255, 0.75);
                    backdrop-filter: blur(8px);
                    position: fixed;
                    bottom: 0px;
                    z-index: 100;
                    height: 64px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin-left: 0;

                    &.step3wrapper {
                        height: 120px;
                    }

                    &.full-order-details{
                        width: 100%;
                        height: 90%;
                        position: fixed;
                        background: #FFFFFF;
                        flex-direction: column;
                        z-index: 99;
                        justify-content: space-between;

                        .bottom-wrapper{
                            background: #F7F8F8;
                            box-shadow: inset 0px 4px 16px rgba(0, 0, 0, 0.1);
                            backdrop-filter: blur(2px);
                        }

                        .order-details {
                            display: block;
                        }
                    }

                    .bottom-wrapper{
                        display: flex;
                        width: 100%;
                        height: 64px;
                        border-bottom: 1px;
                    }

                    .order-details{
                        display: none;
                        .top{
                            margin-top: 15px;

                            .title{
                                font-size: 20px;
                                margin-left: 15px;
                            }
                        }

                        .wrapper{
                            .order-steps{
                                width: 100%;

                                .progress-line-step{
                                    .order-details-title{
                                        margin: 10px 0px 0px 27px;
                                    }
                                }

                                .step{
                                    .progress-line{
                                        left: 15px;
                                    }

                                    .order-details-title{
                                        margin: 10px 0px 0px 27px;
                                    }

                                    .step-text{
                                        margin-left: 37px;
                                    }

                                    .services-wrapper{
                                        width: 100%;

                                        .service-wrapper{
                                            margin-left: 35px;
                                            width: 100%;

                                            .service-details-row{
                                                width: 87%;
                                                justify-content: space-between;

                                                .service-name {
                                                    width: 120px;
                                                }
                                            }

                                            .date-time{
                                                margin-left: 35px;
                                            }
                                        }
                                    }

                                    .total-payment{
                                        width: 86%;
                                        margin-left: 10%;
                                    }
                                }
                            }
                        }
                    }

                    .cart-wrapper{
                        display: block;
                        cursor: pointer;
                        position: relative;
                        width: 85px;

                        .services-quantity{
                            position: absolute;
                            background: #50D58C;
                            width: 16px;
                            height: 16px;
                            border-radius: 20px;
                            top: 11px;
                            right: 16px;
                            line-height: 16px;
                            text-align: center;
                            font-weight: 600;
                            font-size: 12px;
                            color: #FFFFFF;
                        }

                        img{
                            height: 78px;
                            width: 100%;
                            position: relative;
                            top: -7px;
                        }
                    }

                    .separator{
                        width: 1px;
                        height: 42px;
                        background: #D8D8D8;
                        margin-top: 12px;
                        margin-right: 4%;

                        &.step3seperator {
                            height: 99px;
                        }
                    }

                    .next-step-wrapper{
                        display: flex;
                        width: 80%;

                        &.step3 {
                            display: block;
                        }

                        &.no-cart{
                           margin-left: 23px;
                           width: 90%;

                            .next-step{
                                width: auto;
                                margin: 24px 0px 15px;
                            }

                            .next-step-button{
                                width: 58%;
                                position: absolute;
                            }
                        }

                        .next-step{
                            width: 58px;
                            color: #9EA6AA;
                            line-height: 14px;
                            margin: auto;
                        }

                        .next-step-button{
                            right: 1%;
                            margin-top: 12px;
                            height: 31px;
                            font-size: 14px;
                            line-height: 31px;
                            width: 88%;
                            margin-right: 16px;
                        }

                        .agree-sms-warning {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

.agree-sms-warning{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #768287;
    margin: -28px auto 24px 50px;
}