@mixin addToCartButton($width){
    color: #FFFFFF;
    background: #50D58C;
    font-weight: 600;
    font-size: 13px;
    width: ($width);
    padding: 4px 13px 4px 18px;
    height: 37px;
    line-height: 39px;
    border-radius: 44px;
    cursor: pointer;
    margin: 18px auto 40px auto;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
  }

  @mixin disabledButton() {
    pointer-events: none;
    background-color: #9EA6AA;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
  }

  @mixin hoverOnButton() {
    background: rgba(80, 213, 140, 0.1);
    border: 1px solid #50D58C;
    color: #50D58C;
  }

  @mixin price(){
    min-width: 42px;
    padding: 0px 4px;
    height: 19px;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    position: absolute;
    text-align: center;
    line-height: 19px;
    right: 8px;
    background: rgba(59, 76, 83, 0.7);
    border-radius: 4.64px;
}