
.step-title{
  display: flex;
  flex-direction: column;
  margin: 25px 0px;

  .back-wrapper{
    color: #869195;
    display: flex;
    cursor: pointer;
    width: fit-content;

    .back{
      font-weight: 600;
      font-size: 12px;
      margin-left: 18px;
    }

    &:hover{
      color: #3B4C53;
    }
  }

  .title{
    margin: 16px 0px 0px 0px;
    font-weight: 600;
    font-size: 18px;
    color: #3B4C53;

    span{
      font-weight: normal;
      font-size: 14px;
      margin-left: 8px;
    }
  }


  @media only screen and (max-width: 600px) {
    margin: 18px 15px 20px 0px;

    .back-wrapper{
      margin-left: 23px;
    }

    .title{
      font-size: 16px;
      margin: 0px 0px 0px 4%;

      span{
        font-size: 12px;
      }

      &.margin-top {
        margin-top: 8px;
      }
    }

    .subtitle{
      font-size: 12px;
    }

  }
}