@import '../../variables.scss';

.success-page{
  display: flex;
  height: 100%;
  justify-content: space-between;

  .titles{
    display: flex;
    justify-content: space-between;

    &.no-thank-you{
      justify-content: flex-end;
    }

    .title{
      color: #3B4C53;
      font-weight: 600;
      font-size: 18px;
      margin-top: 50px;
      margin-left: 31px;
      text-align: left;
    }

    .booking-summary{
      color: #869195;
      font-weight: 600;
      font-size: 12px;
      margin-top: 58px;
    }
  }


  .pending-approval-msg{
    font-weight: 600;
    font-size: 12px;
    color: #FBAB33;
    text-align: right;
  }

  .summary{
    display: flex;
    flex-direction: column;
    position: relative;

    .summary-card{
      width: 740px;
      margin: 13px 0px;
      height: 392px;
      background: #FFFFFF;
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      padding: 23px 30px 0px 30px;
      display: flex;

      .header-border{
        width: 100%;
        height: 4px;
        background: #F7F8F8;
        border-radius: 24px;
      }

      .summary-titles{
        display: flex;
        padding-bottom: 5px;
        justify-content: space-between;


        .right-title{
          display: flex;
          justify-content: flex-end;
          margin-right: 56px;
          width: 71%;

          .title{
            margin-left: 50px;
            &.item {
              width: 27%;
            }
          }
        }

        .title{
          text-align: left;
          position: relative;
          z-index: 1;
          color: #3B4C53;
          font-weight: 600;
          font-size: 11px;
        }
      }


      .summary-left{
        width: 70%;
        position: relative;

        .services-wrapper{
          width: 100%;

          .services{
            max-height: 256px;
          }

          .service-row{
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            text-align: left;
          //  margin-right: 40px;
            margin-right: 66px;
            border-bottom: 2px solid #F7F8F8;
            padding: 15px 0px 10px 4px;
            color: rgba(59, 76, 83, 0.7);

            .right-data{
              display: flex;
              justify-content: space-between;
              width: 65%;
              margin-right: 27px;


              .data{
                margin-left: 77px;
              }
            }

            .name {
              width: min-content;
              margin-left: 11px;

              &.no-tax {
                min-width: 42%;
                max-width: unset;
                margin-left: 31px;
              }
            }

            &:last-of-type{
              border-bottom:none;
            }

            .tax-amount{
              width: 30px;
              text-align: center;
              margin-left: 63px;
            }

            .quantity{
              text-align: center;
            }

            .total{
              color: #3B4C53;
              width: 40px;
              text-align: center;
              margin-left: 50px!important;
              &.no-tax{
                margin-left: 62px!important;

              }
            }
          }

          .sub-total-separator{
            background: rgba(145, 155, 158, 0.8);
            height: 1.5px;
            width: 93%;
          }

          .sub-total-row{
            display: flex;
            justify-content: space-between;
            color: rgba(59, 76, 83, 0.7);
            font-weight: 600;
            font-size: 12px;
            margin-top: 12px;

            .sub-total-data{
              display: flex;
              justify-content: flex-end;
              margin-right: 58px;
            }

            .total-tax{
              width: 30px;
              text-align: center;
              margin-left: 45px;
            }

            .total-qty{
              width: 30px;
              text-align: center;
            }

            .total-amount{
              width: 30px;
              margin-left: 49px;
            }

          }
        }

        .summary-total{
          display: flex;
          width: 478px;
          padding-right: 11px;
          justify-content: flex-end;
          height: 32px;
          background: #F7F8F8;
          position: absolute;
          bottom: 32px;
          line-height: 32px;

          .items-in-total{
            color: #3B4C53;
            font-weight: 600;
            font-size: 14px;
            margin-right: 6px;
          }

          .total-amount{
            font-size: 16px;
            color: #3B4C53;
          }
        }

        .no-services-mode{
          display: flex;
          flex-direction: column;
          align-items: center;

          img{
            width: 48px;
            height: 42px;
            margin-top: 105px;
          }

          .thank-you{
            font-weight: 600;
            font-size: 18px;
            color: #3B4C53;
            margin-top: 16px;
          }

          .pending-approval{
            color: #FBAB33;
            font-weight: 600;
            font-size: 12px;
            margin-top: 10px;
            width: 278px;
            height: 28px;
            background: rgba(251, 171, 51, 0.1);
            border-radius: 2px;
            line-height: 28px;
            text-align: center;
          }
        }

      }

      .summary-right{
        width: 30%;
        display: flex;
        flex-direction: column;
        border-left: 4px solid #F7F8F8;

        .summary-titles{
          padding-left: 32px;

          &.date-time{
            padding:0px;
            justify-content: center;
            margin-bottom: 5px;

            .title{
              text-align: center;
            }
          }
        }

        .date-and-time{
          display: flex;
          justify-content: center;
          margin-bottom: 6px;

          .date{
            display: flex;
            flex-direction: column;
            color: #3B4C53;
            margin: 21px 0px 0px 16px;

            .month{
              font-weight: 500;
              font-size: 14px;
              text-transform: uppercase;
              text-align: center;
            }

            .day-in-month{
              font-size: 40px;
              line-height: 46px;
              text-align: center;
            }
          }

          .vertical-separator{
            background-color: #D8DBDD;
            width: 1px;
            height: 60px;
            margin: 21px 25px 0px 21px;
          }

          .day-and-time{
            display: flex;
            flex-direction: column;
            color: rgba(59, 76, 83, 0.7);
            font-weight: 500;
            height: 74px;
            font-size: 14px;
            width: 70px;
            margin-top: 21px;
            text-align: center;

            .horizontal-separator{
              width: 60px;
              height: 1px;
              background-color: #D8DBDD;
              margin:  0px 0px 10px 5px;
            }

            .day-name{
              margin-bottom: 10px;
            }
          }
        }

        .location-wrapper{
          display: flex;
          flex-direction: column;
          text-align: left;
          padding-left: 32px;
          margin-top: 39px;

          .location-title{
            color: #3B4C53;
            font-weight: 600;
            font-size: 11px;
            margin-bottom: 13px;
          }

          .address{
            color: rgba(59, 76, 83, 0.7);
            font-size: 12px;
            margin-bottom: 13px;
          }

          .map-wrapper{
            margin-top: 24px;
          }

          #map{
            width: 190px;
            height: 120px;
          }
        }
      }

    }
  }


  .right-pane{
    width: 340px;
    height: 100%;
    background: #FFFFFF;
    text-align: center;
    border-top: 4px solid #6AA8EE;

    img{
      margin: 104px 56px 0px 56px;
    }

    .add-to-calendar{
      width: 144px;
      height: 38px;
      background: #50D58C;
      border-radius: 24px;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      color: #FFFFFF;
      margin: 26px auto 45px;
      line-height: 38px;
      cursor: pointer;
    }

    .in-any-question{
      color: #3B4C53;
      font-size: 12px;
      line-height: 24px;
    }

    .contact-wrapper{
      font-weight: 600;
      font-size: 12px;
      color: #6AA8EE;
      margin-top: 4px;
      display: flex;
      flex-direction: column;

      a{
        margin-bottom: 5px;
        text-decoration: none;
        color: #6AA8EE;
      }
    }
  }


  .item {
    margin: 0 30px;
  }

  @media only screen and (max-width: 1400px) {
    
    .summary {
      margin-right:40px;
      margin-left: 20px;
      width: 66%;

      .summary-card {
        width: 93%;

        .summary-left .summary-total {
          width: 94%;
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
    flex-direction: column;

    .pending-approval-msg{
      text-align: center;
      width: 92%;
      height: auto;
      color: #FBAB33;
      border-top-left-radius: 10px;
      line-height: 32px;
      border-top-right-radius: 10px;
      height: 32px;
      margin: 0 auto;
      background: #fef7eb;
    }

    .summary{
      width: 100%;
      margin: 0;

      .titles{
        flex-direction: row;
        width: 92%;
        text-align: center;
        margin: 26px 4%;

        .title{
          margin: 0;
          margin-left: 28px;

        }

        .booking-summary{
          margin: 0;
          line-height: 27px;
        }
      }

      .summary-card{
        width: 92%;
        height: auto;
        margin: 0 auto;
        padding: 0px;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        .summary-left{
          width: 100%;

          .services-wrapper{
            width: auto;

            .sub-total-separator{
              width: auto;
            }

            .services{
              max-height: 100%;
              overflow: auto;
              .service-row {
                .name{
                  margin-right: 42px;
                }
               .right-data{
                width: 48%;
                margin-right: 77px;
                .data{
                    margin-left: 2px;
                  }
                  .total{
                    margin-left: 63px !important;
                  }
               }
             }
            }

            .sub-total-row{
              .sub-total-data{
                margin-right: -20px;

              }
              .total-qty {
                width: 0px;
                margin-right: 12px;
              }
              .total-tax{
                margin-right: 14px;
  
              }
            }
          }

          .no-services-mode{
            .pending-approval{
              margin: 0px 0px 30px;
              width: 278px;
            }
          }

          .summary-titles{
            padding: 20px 20px 5px;

            .right-title{
              margin-right: -10px;
              width: 0%;

            }

            .right-title.no-tax{
              margin-right: 22px;
              }
          }

          .sub-total-row.no-tax {
            .sub-total-data{
              margin-right: 20px;
            }
          }

          .summary-total{
            bottom: unset;
            position: relative;
            padding: 0px 20px;
            width: auto;
            margin: 20px 20px 20px;
          }

          .header-border{
            padding: 0px 20px;
            width: auto;
            margin: 0 20px;
          }

          .services-wrapper{
            width: auto;
            padding: 0px 20px;

            .service-row{
              margin-right: 57px;
              .name.no-tax {
                min-width: 45%;
                margin-left: 45px;
              }
              .jobType{
                width: min-content;
              }
            }
          }
        }

        .summary-right{
          border-left: none;
          width: 89%;
          margin: 0 auto;

          .summary-titles{
            padding-left: 2px;
          }

          .date-and-time {
            .date{
              margin-left: 0px;
            }
          }

          .location-wrapper{
            padding:0px;
            margin-top: 30px;


            .location-title{
              text-align: center;
              margin-bottom: 0px;
            }

            .map-wrapper{
              margin-bottom: 12px;
              border-radius: 4px;
              overflow: hidden;
              margin-top: 18px;
            }

            .address-and-map{
              display: flex;
              flex-direction: column-reverse;

              .address{

              }
            }
          }
        }
      }
    }

    .right-pane{
      width: 100%;
      background: none;
      border-top: none;

      .add-to-calendar{
        margin: 36px auto;
      }

      &>img {
        display: none;
      }
    }
  }


}