@import '../../../variables.scss';

.total-deposit {
  background: #F7F8F8;
  border-radius: 2px;
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  margin: 20px 25px 0px 32px;
  text-align: right;
  padding-right: 19px;
  z-index: 99;

  .total-deposit-title {
    font-size: 16px;
    margin-left: 5px;
    font-weight: 600;
    color: #3B4C53;
  }

  .amount {
    color: #869195;
    font-size: 16px;
    color: #3B4C53;
  }

  .from-total {
    color: #869195;
    font-size: 10px;
  }

  @media only screen and (max-width: 1300px) {
    width: 92%;
    padding: 0px 4%;
    margin: 20px 0px 15px 0px;
  }

  @media only screen and (max-width: 600px) {
    width: 92%;
  }
}
