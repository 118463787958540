.confirmation-modal-backdrop{
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);

  .confirmation-modal{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: auto;
    margin-right: auto;
    width: 416px;
    height: 184px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 15%);
    border-radius: 16px;
    position: absolute;
    color: #3B4C53;
    display: flex;
    flex-direction: column;
    text-align: center;

    .title{
      font-weight: bold;
      font-size: 16px;
      margin: 23px 32px 10px;
    }

    .sub-title{
      font-size: 12px;
      margin: 0px auto;
      line-height: 20px;
      width: 75%;
    }

    .buttons-wrapper{
      margin: 30px 32px;
      width: auto;
      display: flex;
      justify-content: space-around;

      .button{
        padding: 0px;
        height: 38px;
        line-height: 38px;
        border-radius: 24px;
        cursor: pointer;

        &.approve{
          width: 224px;
          background: #FF6F64;
          color: #FFFFFF;
        }

        &.cancel{
          width: 112px;
          height: 36px;
          line-height: 36px;
          color: #3B4C53;
          background: #FFFFFF;
          border: 1px solid #3B4C53;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      width: 81%;
      height: 282px;
      margin: 0px auto;

      .title{
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        width: 75%;
        margin: 32px auto 10px;
      }

      .buttons-wrapper{
        flex-direction: column-reverse;
        margin: 30px auto;

        .button{
          width: 224px;

          &.cancel{
            width: 224px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}