@import '../../variables.scss';

.step-loading {
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
}

.choose-date {

  .react-datepicker__day--outside-month{
    visibility: hidden
  }

  .datepicker-wrapper {
    width: 528px;
    min-height: 392px;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .get-started {
    color: rgba(102, 102, 102, 0.7);
    font-size: 13px;
    text-align: center;
  }

  .booking-date-picker {
    border: none;
    width: 100%;

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      margin: 14px 40px 6.5px 4px;
      color: #3E4B51;
    }

    .datepicker-header {
      display: flex;
      justify-content: space-between;
      padding: 12px 46px 0px 31px;

      .current-period {
        color: #3B4C53;
        font-weight: 600;
        font-size: 16px;
        padding-top: 6px;
        width: 85px;

        .month{
          margin-right: 5px;
        }
      }

      .change-month-wrapper{
        display: flex;

        .change-month {
          width: 24px;
          height: 24px;
          background: rgba(106, 168, 238, 0.2);
          border-radius: 4px;
          color: #FFFFFF;
          cursor: pointer;
          margin-left: 8px;
          position: relative;

          &:hover{
            background: #6AA8EE;

            &::before {
              border-color: #FFFFFF;
            }
          }

          &::before {
            border-color: #6AA8EE;
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: "";
            display: block;
            height: 7px;
            position: absolute;
            top: 8px;
            right: 13px;
            width: 7px;
            transform: rotate(45deg);
          }

          &.next {

            &::before {
              right: 10px;
              top: 8px;
              transform: rotate(45deg);
            }
          }

          &.previous {

            &::before {
              top: 8px;
              left: 9px;
              transform: rotate(225deg);
            }
          }

        }
      }


    }


    .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
      margin: 7px;
      width: 32px;
      height: 32px;
      margin: 10px 37px 10px 0px;
      line-height: 32px;
      font-weight: 600;
      font-size: 14px;
      color: #6AA8EE;
      border-radius: 3.76471px;
      background: transparent;
      border: 1px solid #6AA8EE;

      &:hover{
        background: rgba(106, 168, 238, 0.1);
        border: 1px solid #6AA8EE;
      }

      &.react-datepicker__day--disabled {
        background: transparent;
        color: rgba(59, 76, 83, 0.4);
        font-weight: 600;
        font-size: 14px;
        border: 1px solid transparent;
      }

      &.react-datepicker__day--selected {
        background: #6AA8EE;
        color: #ffffff;
      }

      &.react-datepicker-highlighted-day {
        color: #3B4C53;
        background: linear-gradient(0deg, rgba(106, 168, 238, 0.6), rgba(106, 168, 238, 0.6)), #FFFFFF;
      }
    }

    .react-datepicker__day-names {
      padding: 10px 0px 0px 11px;
    }

    .react-datepicker__navigation {
      width: 29.12px;
      height: 29.12px;
      top: 28px;
      border-radius: 100px;
      background: $main-color;
      box-shadow: 0px 4.85332px 29.1199px rgba(0, 0, 0, 0.15);

      .react-datepicker__navigation-icon--previous::before {
        border-color: #FFFFFF;
        border-width: 2px 2px 0 0;
        height: 7px;
        top: 8px;
        left: -5px;
        width: 7px;
      }

      .react-datepicker__navigation-icon--next::before {
        border-color: #FFFFFF;
        border-width: 2px 2px 0 0;
        height: 7px;
        top: 8px;
        left: -5px;
        width: 7px;
      }

    }

    .react-datepicker__week{
      text-align: left;
      padding: 0px 0px 0px 25px;
    }

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__header {
        border-bottom: none;
        background: transparent;

      }

      .react-datepicker__day-names {
        width: 100%;
        color: #3B4C53;
        font-weight: 600;
        font-size: 12px;
        margin: 3px auto -14px auto;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .react-datepicker__month {
        width: 100%;
        margin: 0px;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .datepicker-wrapper{
      margin: auto;
    }
  }
  
  @media only screen and (max-width: 904px) {

    padding: 0px;
    display: flex;
    flex-direction: column;

    .datepicker-wrapper{
      width: 92%;
      margin: auto;
      padding: 0px;
      min-height: 335px;
    }

    .datepicker-wrapper > :first-child {
        width: 100%;
    }

    .get-started {
      display: block;
      color: #666666B2;
      font-size: 13px;
      text-align: center;
      margin-top: 10px;
    }

    .booking-date-picker {
      width: 100%;


      .datepicker-header{
        display: flex;
        justify-content: space-between;
        width: 315px;
        margin: 0 auto;
        position: relative;
        padding: 0px;

        .current-period{
          position: absolute;
          width: 100%;
          top: 9px;
        }

        .change-month-wrapper{
          width: 100%;
          justify-content: space-between;

          .change-month{
            top: 10px;

            &.next{
              right: 0px;
              margin-right: 0px;
            }
            &.previous{
              left: 0px;
              margin-left: 0px;
            }
          }
        }

        .change-month.previous::before {
          left: 12px;
          top: 11px;
        }

        .change-month.next::before {
          right: 12px;
          top: 11px;
        }
      }

      .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
        margin: 10px 27px 10px 0px;
      }

      .react-datepicker__day {
        margin: 0px;
      }

      .react-datepicker__day, .react-datepicker__time-name {
        margin: 14px 19px 6.5px 0px;
        height: 24px;
        width: 24px;
        font-size: 12px;
        line-height: 24px;

        &:last-of-type{
          margin-right: 0px;
        }
      }

      .react-datepicker__day-names{
        margin: 14px 27px 6.5px 0px;

        .react-datepicker__day-name{
          margin: 14px 27px 6.5px 0px;
        }
      }



      .react-datepicker__week{
        padding: 0px;
        display: flex;
        justify-content: space-around;
        width: 315px;
        margin: 0 auto;

        &:first-of-type{

          .react-datepicker__day{
            margin-top: 0px;
          }
        }
      }

      .react-datepicker__month-container {
        width: 100%;

        .react-datepicker__day-names {
          display: flex;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          padding: 10px 0px 0px 0px;
          width: 315px;
          margin: 0 auto;
        }

        .react-datepicker__month {
          width: auto;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          margin: 0px 15px 0px 15px;
        }
      }
    }

  }

  @media only screen and (min-width: 601px) and (max-width: 750px) {

    .booking-date-picker{ 
      .datepicker-header, .react-datepicker__week{
        width: 90%;
      }

      .react-datepicker__month-container .react-datepicker__day-names{
        width: 90%;

        .react-datepicker__day-name {
          margin-right: 5%;
        }
      }

      .react-datepicker__day {
        margin-right: 13px;
      }
    }

    .datepicker-wrapper{
      margin:0px 4%;
    }
  }

}