@import '../../variables.scss';

.job-types{
    .job-types-options{
        margin-top: 22px;
        cursor: pointer;
        @media only screen and (max-width: 600px) {
                padding-left: 8px;
            
        }
    }
}



