@import '../../../variables';

.order-details-title{
  height: 25px;
  background: #E8E8E8;
  border-radius: 4px;
  position: relative;
  margin: 10px 0px 0px 50px;
  width: 88px;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  padding: 0px 10px;
  color: rgba(59, 76, 83, 0.7);


  .circle{
    background: #E8E8E8;
    transform: rotate(45deg);
    position: absolute;
    width: 8px;
    height: 8px;
    left: -3px;
    top: 8px;
    border-radius: 2px;
  }

  &.active{
    background: #6AA8EE;
    color: #FFFFFF;

    .circle{
      background: #6AA8EE;
    }
  }
}