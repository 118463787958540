@import '../../variables.scss';

.services-list-wrapper {
  width: 100%;

  .services-list {
    font-family: Poppins;
    padding-left: 30px;
    height: calc(100vh - 193px);
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  
    &::-webkit-scrollbar { 
      width: 8px;
      height: 8px; 
    }

    &::-webkit-scrollbar-thumb {
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      background: #dadce0;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      min-height: 40px;
  }
    &>div {
      margin-bottom: 30px;
    }

    .service-item {
      margin: 0 auto 40px;
    }
  }

  .services-list-title {
    font-weight: 600;
    font-size: 18px;
    color: $title-color;
    margin: 50px 0px 30px 0px;

    span {
      margin-left: 10px;
      font-size: 14px;
      font-weight: normal;
    }
  }

  @media only screen and (max-width: 1400px) {
    .services-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: 1011px) {
    .services-list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: 776px) {
    .services-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    &>div {
      margin-right: 15px;
    }

    .services-list {
      grid-template-columns: repeat(2, 1fr);
      width: 96%;
      margin: 0px 2% 0px 15px;
      padding: 0px;
      overflow-y: inherit;
      height: inherit;

      .services-list-title {
        font-size: 16px;
        text-align: center;
        margin: 35px;
      }

      .service {
        width: 165px;
        height: 280px;
        padding: 0px;
      }
    }

  }

}


