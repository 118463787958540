@import '../../variables.scss';

.choose-time{
  margin-left: 50px;
  width: 248px;

  .available-time{
    margin-top: 134px;
    color: #3B4C53;
    font-weight: 600;
    font-size: 16px;
  }

  .slots-wrapper{
    width: 248px;
    display: grid;
    grid-template-columns: 120px 120px;
    grid-template-columns: 120px 120px;
    column-gap: 8px;
    row-gap: 8px;
    margin-top: 28px;
    font-family: Poppins;

    .slot{
      color: $main-color;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      width: 120px;
      height: 36px;
      font-weight: 600;
      font-size: 14px;
      border: 1px solid $main-color;
      border-radius: 4px;

      &:hover{
        background: rgba(106, 168, 238, 0.1);
        color: $main-color;
        border: 1px solid $main-color;
      }

      &.selected{
        background-color: $main-color;
        border: 1px solid $main-color;
        color: #FFFFFF;
      }
    }

    .no-available-slots{
      width: 240px;
      font-size: 14px;

      .second-row{
        margin-top: 15px;
      }
    }
  }

  @media only screen and (max-width: 1376px) { 
    width: 100%;

    .slots-wrapper{
      grid-template-columns: 100%;
      width: 85%;

      .slot{
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    justify-content: center;
    width: 528px;
    margin: auto;
    margin-top: 43px;

    .available-time{
      margin-top: 7px;
    }

    .slots-wrapper{
      margin: 20px auto 20px auto;
      justify-content: center;
      width: 100%;
      grid-template-columns: 25% 25% 25% 25%;

      .slot{
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 904px) {
    width: 90%;
    margin: 19px 5%;

    .slots-wrapper{
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 700px) {
    .slots-wrapper{
      grid-template-columns: 50% 50%;
    }
  }
}

