@import '../../../variables.scss';
@import "../../../assets/functions.scss";

.service {
  width: 180px;
  height: 280px;
  font-family: Poppins;
  background: #FFFFFF;
  box-shadow: 0px 0px 9.6667px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: flex-start;
  position: relative;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:hover{
    cursor: pointer;
  }

  .image {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    text-align: center;

    img {
      transition: all .2s ease-in-out;
      margin-right: 17px;
      width: 100%;
      height: 140px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .service-no-image-wrapper{
      background: #C4C4C4;
      transition: all .2s ease-in-out;
      height: 140px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      text-align: center;

      .service-no-image {
        width: 50px;
        height: 50px;
        background: #C4C4C4;
        text-align: center;
        margin-top: 50px;
      }
    }

    .price {
      @include price();
      top: 8px;
    }
  }

  .separator {
    width: 32px;
    height: 4px;
    background: #50D58C;
    border-radius: 8px;
    margin:10px 0px 5px 15px;
  }

  .details-quantity {
    padding: 0px 15px;
    display: flex;
    flex-direction: column;

    .service-name {
      font-weight: 600;
      line-height: 21px;
      font-size: 16px;
      word-break: break-word;
    }

    .description {
      font-size: 12px;
      margin-top: 5px;
      color: rgba(59, 76, 83, 0.7);
      word-break: break-word;
    }

  }

  .quantity-wrapper {
    opacity: 0;
    position: absolute;
    transition: all .4s ease-in-out;
  }

  &.has-quantity{

    .image {
      img,.service-no-image-wrapper{
        margin-top: -20px;
      }
    }

    .quantity-selected{
      position: absolute;
      background: #50D58C;
      color: #FFFFFF;
      border-radius: 12px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      right: 15px;
      bottom: 15px;
    }
  }

  &:hover{

    .quantity-selected{
      display: none;
    }

    .image {

      img,.service-no-image-wrapper{
        margin-top: -20px;
      }
      
    }

    .quantity-wrapper {
      opacity: 1;
      display: flex;
      bottom: 13px;
      right: 15px;
    }
  }
  
  &.sticky-quantity {

    .quantity-wrapper {
      opacity: 1;
      display: flex;
      bottom: 13px;
      right: auto;
      margin: 0;
    }

    .image {
      img,.service-no-image-wrapper{
        margin-top: -20px;
      }
    }

    .quantity-selected{
      display: none;
    }

    .separator {
      display: none;
    }

    .details-wrapper {
      padding-top: 8px;
    }
  }

  .info-wrapper{
    padding: 10px;

    .info{
     display: none;
    }

  }

  @media only screen and (max-width: 600px) {
    width: 165px;//354px;
    height: 339px;
    flex-direction: column;
    padding: 0px;
    justify-content: start;
    
  .info-wrapper{
    justify-content: space-between;
    display: flex;
    box-sizing: border-box;

    .quantity-wrapper{
      position: unset;

      .quantity-circle{
        width: 24px;
        height: 24px;
        line-height: 26px;
      }
  
      .quantity{
        margin: 0px 14px;
      }
    }

    .info{
      display: inline-block;
      background-color: #DCF7E8;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      cursor: pointer;

      .info-img{
        height: 56%;
      }
    }

  }

    .details-wrapper {
      display: flex;
      padding: 0px;
      justify-content: space-between;
      height: 102px;

      .details {
        width: auto;

        .service-name{
          font-size: 14px;
        }

        .description {
          width: auto;
        }
      }

      .price-and-quantity {
        .quantity-wrapper {
          margin-top: 12px;

          .price {
            font-weight: 500;
            font-size: 13px;
          }

          .quantity-circle {
            color: #6aa8ef;
            border: solid 0px;
            box-shadow: none;
          }

          .quantity {
            margin: 0;
          }
        }
      }

    }

    .image {
      text-align: center;
      height: 125px;

      img {
        height: 100%;
      }

    }
  }
  
}

.react-tooltip-lite {
    background-color: #FFFFFF;
    color: #3B4C5399;
    box-shadow: 0px 0px 20px 0px #00000026;
    border-radius: 8px;

    .hover-item-name{
      color: #3B4C53;
      font-size: 14px;
      word-break: break-word;
    }

    .hover-description {
      font-size: 13px;
      word-break: break-word;
    }
}

.react-tooltip-lite-arrow {
  color: #FFFFFF;
}

.addToCartButton{
  @include addToCartButton(124px);
  margin: 0 0 10px 0;
  padding: 0px;
  box-sizing: border-box;
  
  span{
    margin: auto;
  }

  &.disabled{
      @include disabledButton();
  }

  &:hover{
      @include hoverOnButton();
  }
}

.quantity-wrapper {
  display: flex;
  margin-top: 10px;
  background-color: #ffffffe0;
  right: 15px;

  .quantity {
    margin: 0px 12px;
    width: 16px;
    color: #3B4C53;
    text-align: center;
  }

  .quantity-circle {
    width: 24px;
    height: 24px;
    text-align: center;
    background: #E8E8E8;
    border-radius: 12px;
    cursor: pointer;
  }
}
